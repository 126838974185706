<template>
  <v-hover v-slot:default="{ hover }">
    <v-card
      :class="`elevation-${hover ? 12 : 2}`"
      class="mx-auto transition-swing"
      @click="$emit('click')"
    >

      <v-img
        :src="thumbnail"
        height="200px"
      >
        <v-icon color="white" class="pa-2 text--shadow">{{icon}}</v-icon>
      </v-img>
      <v-card-title class="pa-4"><div class="header-class subtitle-2">{{ title }}</div></v-card-title>
      <v-card-text v-if="caption !== ''">{{ caption }}</v-card-text>
      <v-card-text v-if="accessibility_text !== ''">{{accessibility_text}}</v-card-text>

    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: "CardGalleryBlockCard",
  props: ["data"],
  mounted(){
  },
  computed: {
    thumbnail() {
      if (this.data.value.thumbnail) 
      {
        if (this.data.value.thumbnail.thumbnail)
          return this.data.value.thumbnail.thumbnail
        return this.data.value.thumbnail;
      }
      return "/pdf-image.png";
    },
    icon() {
      if (this.data.type === 'document')
        return 'mdi-file-document'
      if (this.data.type === 'image')
        return 'mdi-image'
      if (this.data.type === 'video')
        return 'mdi-youtube'
      return ''
    },
    title() {
      if (this.data.value.title)
        return this.data.value.title
      if (this.data.value.page.title)
        return this.data.value.page.title
      return ""
    },
    caption() {
      if (this.data.value.caption)
        return this.data.value.caption
      return ""
    },
    accessibility_text() {
      if (this.data.value.accessibility_text)
        return this.data.value.accessibility_text
      return ""
    }


  },
};
</script>

<style scoped>
.document-bg {
  background-color:lightgrey;
}

.text--shadow {
  text-shadow: 1px 2px 5px black;
  opacity: 0.75;
}

.header-class{
    white-space: nowrap ;
    word-break: normal;
    overflow: hidden ;
    text-overflow: ellipsis;
}
</style>