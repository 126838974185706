<template>
  <div class="full-screen">
    <v-fade-transition>
      <LoadingOverlay class="loading-overlay" :title="data.title" v-show="!loaded" />
    </v-fade-transition>

    <LightboxContentLayout :accessibilityText="data.accessibility_text" :ariaDescription="undefined"
      :captionText="data.caption" :accessibilityTextFlag="false">
      <iframe ref="iframe" :src="data.url" width="100%" height="100%" seamless="true" frameBorder="0"></iframe>
    </LightboxContentLayout>
  </div>

</template>

<script>

import LoadingOverlay from "./LoadingOverlay.vue";
import LightboxContentLayout from './LightboxContentLayout.vue';

export default {
  name: "LightboxIframeCard",
  data: () => ({
    dialog: true,
    loaded: false,
  }),
  mounted() {
    let self = this
    this.$refs.iframe.addEventListener("load", function () {
      self.loaded = true
    });
    console.log("Data from Iframe:", this.data)
  },
  props: ["data"],
  computed: {
    image() {
      if (this.data && this.data.file) return this.data.file;
      return undefined;
    },
    caption() {
      if (this.data.caption)
        return this.data.caption
      return ''
    },
  },
  components: { LoadingOverlay, LightboxContentLayout }
};
</script>


<style scoped>
.full-screen {
  width: 100%;
  height: 100%;
}

.loading-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: white;
}
</style>