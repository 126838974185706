<template>
  <div justify="center">
    <v-img :src="url" :max-height="height" contain></v-img>
  </div>
</template>

<script>
export default {
  name: "ImageBlock",
  props: ['url', 'width', 'height']
};
</script>

<style scoped>
</style>