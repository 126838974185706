import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VScaleTransition } from 'vuetify/lib/components/transitions';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.validIcon)?_c('div',{ref:"icon",staticClass:"icon-div"},[_c(VContainer,{directives:[{name:"show",rawName:"v-show",value:(true || (_vm.showTooltip && !_vm.debug)),expression:"true || (showTooltip && !debug)"}],staticClass:"hotspot-hint fluid pa-0",attrs:{"fill-height":""}},[_c(VRow,{staticClass:"text-center pa-0 ma-0",attrs:{"align":"center","justify":"center"}},[_c(VScaleTransition,{attrs:{"origin":"center center"}},[_c('ConcentricCircles',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showTooltip && !_vm.isCentered),expression:"!showTooltip && !isCentered"}],staticClass:"hotspot-circle"})],1),_c(VScaleTransition,{attrs:{"origin":"center center"}},[_c(VCard,{directives:[{name:"show",rawName:"v-show",value:((!_vm.showTooltip && !_vm.isCentered) || _vm.title === ''),expression:"(!showTooltip && !isCentered) || title === ''"}],class:!_vm.showTooltip
                ? 'pa-0 hotspot-title hotspot-icon'
                : 'pa-1 hotspot-title hotspot-icon',attrs:{"dark":"","color":"secondary","rounded":"pill"}},[_c(VIcon,{staticClass:"pa-3"},[_vm._v(_vm._s(_vm.icon))])],1)],1),(_vm.title !== '')?_c(VScaleTransition,{attrs:{"origin":"center center"}},[_c(VCard,{directives:[{name:"show",rawName:"v-show",value:(_vm.showTooltip || _vm.isCentered),expression:"showTooltip || isCentered"}],class:_vm.showTooltip
                ? 'pa-1 hotspot-title hotspot-icon'
                : 'pa-0 hotspot-title hotspot-icon',attrs:{"dark":"","color":"secondary","rounded":"pill"}},[_c(VCardSubtitle,{staticClass:"pa-2 ml-2 mr-2 white--text"},[_c(VIcon,{staticClass:"pr-2"},[_vm._v(_vm._s(_vm.icon))]),_vm._v(" "+_vm._s(_vm.title)+" ")],1)],1)],1):_vm._e()],1)],1)],1):_vm._e(),_c(VScaleTransition,{attrs:{"origin":"center center"}},[_c('div',{ref:"rect",staticClass:"hotspot-bg hotspot-btn",style:({
        width: _vm.hotspotSizeString.width,
        height: _vm.hotspotSizeString.height,
      }),attrs:{"alt":""},on:{"mouseenter":function($event){_vm.showTooltip = true},"mouseleave":function($event){_vm.showTooltip = false},"mousemove":function($event){_vm.showTooltip = true}}},[_c('div',[(_vm.debug === true)?_c(VImg,{style:({
            width: _vm.hotspotSizeString.width,
            height: _vm.hotspotSizeString.height,
            opacity: 0.5,
          }),attrs:{"src":"/grid.png","tab-index":"-1","alt":"","id":"hotspot-img"}}):_c(VBtn,{style:({
            width: _vm.hotspotSizeString.width,
            height: _vm.hotspotSizeString.height,
          }),attrs:{"text":"","dark":"","aria-label":_vm.ariaLabel,"disabled":!_vm.clickable,"id":"hotspot-img"},on:{"click":_vm.clicked,"mousedown":_vm.mouseDown,"mousemove":_vm.mouseMove,"focus":_vm.onFocus}},[_c('div',{class:_vm.transparent ? 'img-no-background' : 'img-background',attrs:{"tabindex":"-1"}},[(_vm.hasThumbnail)?_c(VImg,{style:({
                width: _vm.hotspotSizeString.width,
                height: _vm.hotspotSizeString.height,
              }),attrs:{"src":_vm.thumbnail.file,"contain":"","tabindex":"-1","alt":""}}):_vm._e()],1),_vm._t("default")],2),_c(VFadeTransition,[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTooltip && !_vm.debug && _vm.clickable),expression:"showTooltip && !debug && clickable"}],staticClass:"hotspot-overlay"},[_c(VContainer,{staticClass:"background",attrs:{"fill-height":"","fluid":""}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol)],1)],1)],1)])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }