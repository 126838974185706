<template>
  <v-row justify="center">
    <video class="video" controls controlsList="nodownload" disablepictureinpicture width="100%" :poster="thumbnail">
      <source :src="url" type="video/mp4" />
    </video>
  </v-row>
</template>

<script>
export default {
  name: "ImageBlock",
  props: ['url', 'width', 'height', 'thumbnail']
};
</script>

<style scoped>
</style>