import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{staticClass:"mx-auto transition-swing",class:("elevation-" + (hover ? 12 : 2)),on:{"click":function($event){return _vm.$emit('click')}}},[_c(VImg,{attrs:{"src":_vm.thumbnail,"height":"200px"}},[_c(VIcon,{staticClass:"pa-2 text--shadow",attrs:{"color":"white"}},[_vm._v(_vm._s(_vm.icon))])],1),_c(VCardTitle,{staticClass:"pa-4"},[_c('div',{staticClass:"header-class subtitle-2"},[_vm._v(_vm._s(_vm.title))])]),(_vm.caption !== '')?_c(VCardText,[_vm._v(_vm._s(_vm.caption))]):_vm._e(),(_vm.accessibility_text !== '')?_c(VCardText,[_vm._v(_vm._s(_vm.accessibility_text))]):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }