import { VApp } from 'vuetify/lib/components/VApp';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,{staticClass:"pl-4 pr-4",attrs:{"tabindex":"-1","id":"inspire"}},[_c('div',{staticClass:"engage-header",attrs:{"tabindex":"-1"}},[_c(VNavigationDrawer,{attrs:{"app":"","temporary":"","value":_vm.isHomeNavigationDrawerOpen,"width":"300"},on:{"input":_vm.TOGGLE_HOME_NAVIGATION_DRAWER}},[_c('div',{staticClass:"pl-4 pr-4 primary-logo",style:({ background: _vm.$vuetify.theme.themes.light.primary }),attrs:{"id":"header","role":"banner"}},[_c(VImg,{attrs:{"width":"100%","height":"96px","contain":"","src":_vm.siteLogo}})],1),_c('div',{staticClass:"app-menu",attrs:{"id":"nav","role":"navigation","aria-label":"Primary"}},[_c(VList,{attrs:{"nav":"","dense":""}},[_vm._l((_vm.siteStructure),function(menuItem,i){return _c(VListItem,{key:i,style:(_vm.navStyle(menuItem)),attrs:{"to":menuItem.pathname,"link":"","color":"secondary"}},[_c(VListItemIcon,{staticClass:"mr-4"},[_c(VIcon,[_vm._v(_vm._s(menuItem.menu_icon))])],1),_c(VListItemTitle,[_vm._v(_vm._s(menuItem.title))])],1)}),_c(VListItem,{on:{"click":function($event){$event.preventDefault();return _vm.logOut.apply(null, arguments)}}},[_c(VListItemIcon,{staticClass:"mr-4"},[_c(VIcon,[_vm._v("mdi-logout")])],1),_c(VListItemTitle,[_vm._v("Logout")])],1)],2)],1),_c('div',{staticClass:"pl-4 pr-4 secondary-logo",style:({ background: _vm.$vuetify.theme.themes.light.primary })},[_c(VImg,{attrs:{"width":"100%","height":"100%","contain":"","src":_vm.secondaryLogo}})],1)])],1),_c(VMain,{class:_vm.showHeader ? '' : 'pt-0',attrs:{"id":"content","role":"main","tabindex":"-1"}},[_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }