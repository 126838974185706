import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scrollable",staticStyle:{"z-index":"3"},attrs:{"id":"sidebar-container"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }$event.preventDefault();return _vm.testFunc()}}},[_c(VToolbar,{attrs:{"dark":"","color":"primary lighten-1","elevation":"0"}},[(_vm.pageData.meta.parent !== null)?_c(VBtn,{attrs:{"tabindex":"-1","icon":"","dark":""},on:{"click":function($event){return _vm.back()}}},[_c(VIcon,[_vm._v("mdi-arrow-left-circle")])],1):_vm._e(),_c(VToolbarTitle,[_vm._v(" "+_vm._s(_vm.pageData.title))]),_c(VSpacer)],1),_c(VContainer,{staticClass:"pb-0"},_vm._l((_vm.pageData.description),function(block,i){return _c(VRow,{key:i},[_c(VCol,[_c('Streamfield',{attrs:{"block":block}})],1)],1)}),1),(_vm.childPages.length > 0)?_c(VContainer,{staticClass:"pb-0"},[_c(VRow,[_c(VCol,{staticClass:"pb-3"},[_c(VDivider)],1)],1),_c(VRow,[_c(VCol,{staticClass:"pb-0"},[_c(VAlert,{staticClass:"mb-0",attrs:{"type":"info","color":"secondary","border":"left"}},[_vm._v(" Click on the topics below for more information ")])],1)],1),_vm._l((_vm.childPages),function(menuItem,i){return _c(VRow,{key:i},[_c(VCol,{staticClass:"pb-0"},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{style:(_vm.getBorderStyle(
                  _vm.getPrimaryColour(menuItem.primary_colour_override)
                )),attrs:{"elevation":hover ? 4 : 1},on:{"click":function($event){return _vm.clickChild(menuItem)}}},[_c(VCardText,{staticClass:"text-subtitle-1 card-text"},[_c(VIcon,{staticClass:"mr-4",attrs:{"color":_vm.getPrimaryColour(menuItem.primary_colour_override)}},[_vm._v(_vm._s(menuItem.menu_icon))]),_vm._v(_vm._s(menuItem.title))],1)],1)]}}],null,true)})],1)],1)}),_c(VRow,[_c(VCol,{staticClass:"pt-6 pb-0"},[_c(VDivider)],1)],1)],2):_vm._e(),_c(VContainer,[(_vm.pageData.next_map !== null)?_c(VRow,[_c(VCol,{staticClass:"pb-0"},[_c(VBtn,{attrs:{"block":"","color":"primary lighten-1"},on:{"click":function($event){return _vm.gotoPage(_vm.pageData.next_map.html_url)}}},[_vm._v("Next - "+_vm._s(_vm.pageData.next_map.title))])],1)],1):_vm._e(),(
        _vm.pageData.root_map !== null && _vm.pageData.root_map.id !== _vm.pageData.id
      )?_c(VRow,[_c(VCol,{staticClass:"pb-0"},[_c(VBtn,{attrs:{"block":"","color":"primary lighten-1"},on:{"click":function($event){return _vm.gotoPage(_vm.pageData.root_map.html_url)}}},[_vm._v("Home - "+_vm._s(_vm.pageData.root_map.title))])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }