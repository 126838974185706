<template>
    <v-container>
      <v-row :justify="'start'">
        <span v-for="(button, i) in data" :key="i" class="ma-1">
          <v-btn v-if="button.button_type[0].type === 'document_link'"  target="_blank" :aria-label="button.accessibility_text" :href="button.button_type[0].value.file">
            <v-icon left>{{button.icon}}</v-icon>
            {{button.title}}
          </v-btn>
          <v-btn v-else-if="button.button_type[0].type === 'other_link'"  target="_blank" :aria-label="button.accessibility_text" :href="button.button_type[0].value">
            <v-icon left>{{button.icon}}</v-icon>
            {{button.title}} 
          </v-btn>
          <v-btn v-else-if="button.button_type[0].type === 'page_link'"  :aria-label="button.accessibility_text" @click="getPageLink(button)">
            <v-icon left>{{button.icon}}</v-icon>
            {{button.title}} 
          </v-btn>
        </span>
      </v-row>
    </v-container>

</template>
<script>
export default {
  name: "ButtonLinkRowBlock",
  props: ["data"],
  methods: {
    getPageLink(button){
      const backendUrl = button.button_type[0].value.meta.html_url
      return this.$router.push(new URL(backendUrl).pathname)
    }
  }
};
</script>

<style scoped>
</style>