<template>
  <div>
    <!-- <div class="item">
      <img src="https://www.kirupa.com/images/orange.png" />
    </div> -->
    <div class="circle" style="animation-delay: 0s"></div>
    <div class="circle" style="animation-delay: 2s"></div>
    <!-- <div class="circle" style="animation-delay: 1.0s"></div> -->
    <!-- <div class="circle" style="animation-delay: 3s"></div> -->
  </div>
</template>

<script>
export default {
  name: "ConcentricCircles",
  props: [
  ],
  data: () => ({ showTooltip: false, hasMoved: false, hasClicked: false }),
  components: {},
  mounted: function () {
  },
  methods: {
  },
  computed: {
  },
  watch: {
  },
};
</script>

<style scoped>

.circle {
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  border-color: white;
  background-color: rgba(255,255,255,0.5);
  width: 40px;
  height: 40px;
  margin-left: -20px;
  margin-top: -20px;
  position: absolute;
  opacity: 0;
  animation: scaleIn 4s infinite ease-out;
}

@keyframes scaleIn {
  from {
    transform: scale(1.0, 1.0);
    opacity: 1.0;
  }
  to {
    transform: scale(2.0, 2.0);
    opacity: 0;
  }
}

.item {
  z-index: 100;
  padding: 5px;
}

.item img {
  width: 150px;
}
</style>