import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[(_vm.groupData.title !== '')?_c(VCardTitle,{staticClass:"text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.groupData.title)+" ")]):_vm._e(),(_vm.groupData.subtitle !== '')?_c(VCardSubtitle,{staticClass:"text-subtitle-2"},[_vm._v(" "+_vm._s(_vm.groupData.subtitle)+" ")]):_vm._e(),_c(VRadioGroup,{directives:[{name:"show",rawName:"v-show",value:(_vm.groupData.group_item_type == 'radioBtn'),expression:"groupData.group_item_type == 'radioBtn'"}],staticClass:"mt-0",attrs:{"color":"primary","mandatory":""},model:{value:(_vm.groupValue),callback:function ($$v) {_vm.groupValue=$$v},expression:"groupValue"}},_vm._l((_vm.groupData.layers),function(item,i){return _c(VListItem,{key:i,attrs:{"color":"primary"}},[_c(VListItemContent,[_c(VListItemTitle,[_c(VRadio,{attrs:{"label":item.nice_name,"value":i}})],1),_vm._l((item.additional_detail),function(block,blockIndex){return _c('StreamfieldBlock',{directives:[{name:"show",rawName:"v-show",value:(_vm.groupValue == i),expression:"groupValue == i"}],key:blockIndex,attrs:{"id":i,"block":block}})})],2)],1)}),1),_c(VList,{directives:[{name:"show",rawName:"v-show",value:(_vm.groupData.group_item_type == 'checkBox'),expression:"groupData.group_item_type == 'checkBox'"}]},[_c(VListItemGroup,{staticClass:"mt-0",attrs:{"multiple":"","color":"primary"},model:{value:(_vm.checkboxGroupValue),callback:function ($$v) {_vm.checkboxGroupValue=$$v},expression:"checkboxGroupValue"}},_vm._l((_vm.groupData.layers),function(item,i){return _c(VListItem,{key:i,attrs:{"value":item.layer_name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c(VListItemAction,[_c(VCheckbox,{attrs:{"input-value":active,"color":"secondary"}})],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.nice_name)}}),_vm._l((item.additional_detail),function(block,blockIndex){return _c('StreamfieldBlock',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkboxGroupValue.includes(item.layer_name)),expression:"checkboxGroupValue.includes(item.layer_name)"}],key:blockIndex,attrs:{"id":i,"block":block}})})],2)]}}],null,true)})}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }