import AuthService from './auth.service';
import apiUrl from './api-url'

const axiosApiInstance = AuthService.axiosApiInstance;

class WagtailService {
  getComments(mapPageId) {
    let params = {map: mapPageId}
    return axiosApiInstance.get(apiUrl() + 'api/map-comments/', params);
  }

  getSiteSettings() {
    return axiosApiInstance.get(apiUrl() + 'api/site-settings/');
  }

  getSiteStructure() {
    return axiosApiInstance.get(apiUrl() + 'cms-api/pages/?show_in_menus=true&type=engage_pages.HeadlessPageBase&fields=menu_icon');
  }

  getPage(id) {
    return axiosApiInstance.get(apiUrl() + 'cms-api/pages/find/?html_path=' + id );
  }


  getPageByUrl(url) {
    return axiosApiInstance.get(url);
  }


  getPageById(id) {
    return axiosApiInstance.get(apiUrl() + 'cms-api/pages/' + id );
  }


  getPageChildren(id) {
    return axiosApiInstance.get(apiUrl() + 'cms-api/pages/?child_of=' + id );
  }

  getEsriTokens() {
    return axiosApiInstance.get(apiUrl() + 'api/tokens/');
  }

  savePage(id, data) {
    console.log(data)
    return axiosApiInstance.patch(apiUrl() + 'api/map-pages/' + id + '/', {title: "Hello"})
  }
}

export default new WagtailService();
