<template>
  <div>
    <div v-if="currentPage !== undefined && error === undefined">
      <div :key="currentPage.id">
        <EngagementRoom
          v-if="currentPage.meta.type === 'engage_virtualrooms.VirtualRoomPage'"
          :pageData="currentPage"
          :pageChildren="currentChildren"
        />
        <Page
          v-if="currentPage.meta.type === 'wagtailcore.Page'"
          :pageData="currentPage"
          :pageChildren="currentChildren"
        />
        <WebPageLegacy
          v-if="currentPage.meta.type === 'engage_pages.WebPageLegacy'"
          :pageData="currentPage"
          :pageChildren="currentChildren"
        />
        <WebPage
          v-if="currentPage.meta.type === 'engage_pages.WebPage'"
          :pageData="currentPage"
          :pageChildren="currentChildren"
        />
        <MapPage
          v-if="currentPage.meta.type === 'engage_maps.MapPage'"
          :pageData="currentPage"
          :pageChildren="currentChildren"
        />
      </div>
    </div>
    <div v-else-if="error !== undefined">
      <Error404 v-if="error.response.status === 404"></Error404>
    </div>
    <LoadingScreen v-show="loading" />
  </div>
</template>

<script>
import Vue from "vue";

import Page from "./wagtail/Page.vue";
import EngagementRoom from "./wagtail/EngagementRoom.vue";
import WebPageLegacy from "./wagtail/WebPageLegacy.vue";
import MapPage from "./wagtail/MapPage.vue";
import WebPage from "./wagtail/WebPage.vue"
import Error404 from "./wagtail/Error404.vue";

import LoadingScreen from "../components/loadingScreen/LoadingScreen.vue";

export default {
  name: "WagtailPage",
  data: () => ({
    dialog: true,
    error: undefined,
    loading: false,
  }),
  components: {
    Page,
    EngagementRoom,
    WebPageLegacy,
    MapPage,
    WebPage,
    Error404,
    LoadingScreen,
  },
  mounted() {
    // Load the page data for this page
    console.log("Wagtail page mounted");
    this.loadPage(this.$route.path);
  },
  computed: {
    currentPath() {
      return this.$route.path;
    },
    currentPage() {
      return this.$store.state.wagtail.currentPage;
    },
    currentChildren() {
      return this.$store.state.wagtail.currentChildren;
    },
  },
  watch: {
    currentPath(newPath) {
      // If the path changes, we want to load new page data from the API      
      console.log("Wagtail page path changed");
      this.loadPage(newPath);
      // brings back scrollbars
      var bodyElement = document.querySelector("body");
      var htmlElement = document.querySelector("html");
      bodyElement.classList.remove("body-scrollhide");
      htmlElement.classList.remove("html-scrollhide");
      bodyElement.classList.remove("body-scrollhider");
      bodyElement.classList.remove("body-scrollshow");
       
    
    },
  },
  methods: {
    updatePageTitle(title) {
      Vue.nextTick(() => {
        document.title = title || "Engage";
      });
    },
    loadPage(path) {
      this.error = undefined;
      let self = this;

      if (this.currentPage !== undefined) {
        let targetPath = path;
        let currentPath = new URL(this.currentPage.meta.html_url).pathname;

        // Skip loading if we're just trying to load the current page
        if (targetPath === currentPath) {
          self.updatePageTitle(this.currentPage.title);
          return;
        }

        // If current page is a virtual room, Iterate children and if we find that we're
        // requesting a child we skip dispatching a load as we already have the room loaded.
        if (
          this.currentPage.meta.type === "engage_virtualrooms.VirtualRoomPage"
        ) {
          for (let child of this.currentPage.child_content) {
            let childPath = new URL(child.html_url).pathname;
            if (childPath === targetPath) {
              let loggedIn = self.$store.state.auth.status.loggedIn;
              // TODO: This isn't the most elegant solution, and requires supporting all Wagtail privacy types
              // If the page isn't restricted, or we're logged in, skip dispatching a load
              // Otherwise we'll trigger a proper reload, which will force a login screen if required
              if (!child.restricted || loggedIn) {
                self.updatePageTitle(child.title);
                return; //We skip dispatching a load if we're requesting a virtual room popup
              }
            }
          }
        }
      }

      // Trigger the loading screen
      this.loading = true;

      this.$store.dispatch("wagtail/getPage", path).then(
        (response) => {
          self.loading = false;

          // If we encounter a redirect page, we push a new URL to the rounter
          if (response.meta.type === "engage_pages.RedirectPage") {
            if (response.target_page !== undefined) {
              var path = new URL(response.target_page).pathname;
              self.$router.push(path);
            }
          }

          self.updatePageTitle(response.title);
        },
        (error) => {
          self.loading = false;

          if (
            error.response.status === 404 &&
            self.$store.state.auth.status.loggedIn
          ) {
            self.error = error;
          } else {
            self.error = undefined;
            self.$router.push("/login?redirect=" + window.location.pathname);
          }
        }
      );
    },
  },
};
</script>

<style scoped>
</style>