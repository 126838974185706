<template>
  <div>
    <PageHeaderCard v-if="showHeader"  :transparent="false" class="map-header"> </PageHeaderCard>

    <!-- Page Header -->
     <StreamfieldBlock
        v-for="(block, i) in pageData.header"
        :key="i"
        :block="block"
        :headerTitle="pageData.title"
      />

    <!-- Page Content -->
    <v-container class = "pa-0" fluid>
      <StreamfieldBlock
        v-for="(block, i) in pageData.content"
        :key="i"
        :block="block"
      />
    </v-container>
    
    <LandingStreamfield ref="landing" :pageType="'Page'" :data="this.pageData">
    </LandingStreamfield>
  </div>
</template>

<script>
import StreamfieldBlock from "../../components/blocks/streamfieldBlock.vue";
import PageHeaderCard from "../../components/page/pageHeaderCard.vue";
import LandingStreamfield from "../../components/misc/LandingStreamfield.vue";

export default {
  name: "WebPage",
  props: {
    pageData: Object,
    pageChildren: Array,
    showTitle: { type: Boolean, default: true },
    showHeader: {type: Boolean, default: true },
  },
  components: { StreamfieldBlock: StreamfieldBlock, PageHeaderCard, LandingStreamfield },
  data: () => ({showLanding: Boolean}),
  mounted() {
   if (this.pageData.landing_streamfield.length >= 1 ){
      this.$refs.landing.welcomeOpen();
    }else this.$refs.landing.welcomeClosed();
  console.log(this.pageData, this.showLanding ,"page data from webpage")},
  computed: {},
  methods: {},
};
</script>

<style scoped>
</style>