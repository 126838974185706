import { render, staticRenderFns } from "./LightboxDocumentCard.vue?vue&type=template&id=6b67102e&scoped=true&"
import script from "./LightboxDocumentCard.vue?vue&type=script&lang=js&"
export * from "./LightboxDocumentCard.vue?vue&type=script&lang=js&"
import style0 from "./LightboxDocumentCard.vue?vue&type=style&index=0&id=6b67102e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b67102e",
  null
  
)

export default component.exports