<template>
  <div>
    <v-alert
      class="ml-0 mb-0 mr-0"
      text
      :icon="data.icon"
      :color="data.color"
      ><div :class="getCustomCssClass(data)"><ParagraphBlock :html="data.content"></ParagraphBlock>  </div>
</v-alert>
  </div>
</template>

<script>

import ParagraphBlock from "./paragraphBlock.vue"

export default {
  name: "ColoredNotificationBlock",
  props: ["data"],
  data: () => ({
  }),
  components: {ParagraphBlock},
  mounted(){
    console.log(this.data, "text froim colored")
  },
  methods:{
    getCustomCssClass(data) {
      if (data.textColor !== null) {
        console.log(
          "getCustomCssClass()",
          data.textColor
        );
        return data.textColor;
      }
      return "";
    },
  }
};
</script>

<style scoped>
</style>