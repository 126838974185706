<template>
  <v-fade-transition>
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-col>
          <v-row align="center" justify="center">
            <div class="text-center">
              <v-progress-circular
                indeterminate
                size="50"
                color="secondary"
              ></v-progress-circular>
            </div>
          </v-row>
          <v-row align="center" justify="center" v-if="title !== '' && title !== undefined">
            <div class="text-center secondary--text pt-6">
              <span>Loading {{title}}</span>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-fade-transition>
</template>

<script>
export default {
  name: "Loading",
  props: ['title'],
  data: () => ({
    items: [],
    select: undefined,
    search: undefined,
    loading: undefined,
    toggle_exclusive: 1,
  }),
};
</script>

<style scoped>
.background {
  background-color: rgba(255, 255, 255, 0.5);
}
</style>