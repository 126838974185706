import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VScaleTransition } from 'vuetify/lib/components/transitions';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fill-height":"","fluid":""}},[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"8","md":"6","lg":"4","xl":"3"}},[_c(VRow,{staticClass:"pl-6 pr-6",attrs:{"justify":"center"}},[_c(VScaleTransition,[_c(VAlert,{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading && _vm.message !== ''),expression:"!loading && message !== ''"}],attrs:{"border":"top","colored-border":"","type":"error","elevation":"2"}},[_vm._v(_vm._s(_vm.message))])],1),_c(VCard,{attrs:{"width":"100%"}},[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VToolbarTitle,[_vm._v("Login")])],1),_c(VContainer,{staticClass:"pa-7"},[_c(VRow,{attrs:{"justify":"center"}},[_c(VTextField,{attrs:{"label":"Username","placeholder":"Username","id":"username","required":"","outlined":"","dense":"","prepend-icon":"mdi-account"},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}})],1),_c(VRow,{attrs:{"justify":"center"}},[_c(VTextField,{attrs:{"id":"password","label":"Password","placeholder":"Password","required":"","outlined":"","dense":"","prepend-icon":"mdi-lock","type":"password"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1),_c(VRow,{attrs:{"justify":"center"}},[_c(VBtn,{attrs:{"id":"login-btn","color":"primary"},on:{"click":_vm.handleLogin}},[_vm._v(" Login ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }