<template>
  <v-row>
    <v-col
      cols="12"
      sm="4"
      md="4"
      lg="4"
      xl="4"
      class="streamfield-btn"
      v-for="(value, key) of data"
      :key="key"
      >
      <StreamfieldBlock v-for="(block, i) in value" :key="i" :block="block"/>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: "ThreeColumnBlock",
  props: ["data"],
  beforeCreate: function () {
    //Prevents recustive importing...
    this.$options.components.StreamfieldBlock = require('../streamfieldBlock.vue').default
  }
};
</script>

<style scoped>
</style>