import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VToolbar,{class:_vm.transparent ? 'pass-thru' : 'clickable',attrs:{"dark":"","color":_vm.transparent ? 'transparent' : 'primary',"elevation":_vm.transparent ? 0 : 2,"height":_vm.height}},[_c(VAppBarNavIcon,{staticClass:"clickable",attrs:{"dark":""},on:{"click":_vm.TOGGLE_HOME_NAVIGATION_DRAWER}}),_c(VAppBarNavIcon,{staticClass:"clickable accessibility-menu-btn",attrs:{"dark":"","id":"accessibility-menu-btn"},on:{"click":function($event){return _vm.accessibilityModeActive()}}},[_c(VIcon,[_vm._v(" mdi-human ")])],1),_c(VImg,{attrs:{"height":"100%","src":_vm.siteLogo,"contain":"","position":"left"}}),_c(VToolbarItems,{directives:[{name:"show",rawName:"v-show",value:(!_vm.transparent),expression:"!transparent"}],attrs:{"aria-label":"secondary","role":"navigation"}},_vm._l((_vm.siteStructure),function(item){return _c(VBtn,{key:item.title,attrs:{"text":"","to":item.pathname}},[_c(VIcon,{directives:[{name:"show",rawName:"v-show",value:(item.menu_icon == 'mdi-home'),expression:"item.menu_icon == 'mdi-home'"}],attrs:{"left":"","dark":""}},[_vm._v(_vm._s(item.menu_icon))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.menu_icon !== 'mdi-home'),expression:"item.menu_icon !== 'mdi-home'"}]},[_vm._v(_vm._s(item.title)+" ")])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }