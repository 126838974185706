<template>
  <div>
    <PageHeaderCard v-if="showHeader"  :transparent="false" class="map-header"> </PageHeaderCard>
    <v-container>
      <v-row v-if="showTitle">
        <v-col>
          <h1 class="pb-4">{{ pageData.title }}</h1>
        </v-col>
      </v-row>
      <StreamfieldBlock
        v-for="(block, i) in pageData.content"
        :key="i"
        :block="block"
      />
    </v-container>
      <LandingStreamfield ref="landing" :pageType="'Page'" :data="this.pageData">
    </LandingStreamfield>
  </div>
</template>

<script>
import StreamfieldBlock from "../../components/blocks/streamfieldBlock.vue";
import PageHeaderCard from "../../components/page/pageHeaderCard.vue";
import LandingStreamfield from "../../components/misc/LandingStreamfield.vue"

export default {
  name: "WebPageLegacy",
  props: {
    pageData: Object,
    pageChildren: Array,
    showTitle: { type: Boolean, default: true },
    showHeader: {type: Boolean, default: true },
  },
  components: { StreamfieldBlock: StreamfieldBlock, PageHeaderCard, LandingStreamfield},
  data: () => ({  showLanding: Boolean }),
  mounted() {
    if (this.pageData.landing_streamfield.length >= 1 ){
      this.$refs.landing.welcomeOpen();
    }else this.$refs.landing.welcomeClosed();
  console.log(this.pageData, this.showLanding ,"page data from webpage")},
  computed: {},
  methods: {},
};
</script>

<style scoped>
</style>