import Vue from 'vue';
import WagtailService from '../services/wagtail.service';

export const wagtail = {
  namespaced: true,
  state: {
    mapComments: [
    ],
    rootPage: undefined,
    currentPage: undefined,
    currentChildren: [],
    currentPopup: undefined,
    esriTokens: [],
    newComment: undefined,
    activeTool: 'map',
    showLegend: false,
    layerFilter: null,
    layerOptions: {},
    sceneid: "c5c3b634295c4474bfa03e5a0af96f3c",
    webSceneId: "undefined",
    siteStructure: [],
    siteSettings: {},
    showHeader: true,
    accessibilityMode: false,
  },

  actions: {
    getComments({ commit }, mapPageId) {
      return WagtailService.getComments(mapPageId).then(
        response => {
          commit('getCommentsSuccess', response.data);
          return Promise.resolve(response.data);
        },
        error => {
          commit('getCommentsFailure');
          return Promise.reject(error);
        }
      );
    },
    placeComment({ state, commit }, screenpoint) {
      let data = {
        "lng": screenpoint[0],
        "lat": screenpoint[1],
        "name": "",
        "comment": "",
        "sentiment": "happy",
        "map": state.currentPage.id
      }
      commit('addComment', data);
    },

    getEsriTokens({ commit }) {
      return WagtailService.getEsriTokens().then(
        response => {
          commit('getEsriTokenSuccess', response.data);
          return Promise.resolve(response.data);
        },
        error => {
          commit('getEsriTokenFailure');
          return Promise.reject(error);
        }
      );
    },

    getRootPage({ dispatch, commit }, id) {
      return WagtailService.getPage(id).then(
        response => {
          commit('getPageSuccess', response.data);
          dispatch('getPageChildren', response.data.id)
          return Promise.resolve(response.data);
        },
        error => {
          commit('getPageFailure');
          return Promise.reject(error);
        }
      );
    },

    getSiteSettings({ commit }) {
      return WagtailService.getSiteSettings().then(
        response => {
          commit('getSiteSettingsSuccess', response.data);
          return Promise.resolve(response.data);
        },
        error => {
          commit('getSiteSettingsFailure');
          return Promise.reject(error);
        }
      );
    },

    getSiteStructure({ commit }) {
      return WagtailService.getSiteStructure().then(
        response => {
          commit('getSiteStructureSuccess', response.data);
          return Promise.resolve(response.data);
        },
        error => {
          commit('getSiteStructureFailure');
          return Promise.reject(error);
        }
      );
    },

    getPage({ dispatch, commit }, id) {
      return WagtailService.getPage(id).then(
        response => {
          if (response.data.meta.type === "engage_virtualrooms.VirtualRoomContent")
          {
            // If trying to load virtual room content, what we actually want to load is the parent virtual room page
            // Then the engagement room page will take care of any additional routing requirements
            return WagtailService.getPageById(response.data.meta.parent.id).then(
              response => {
                commit('getPageSuccess', response.data);
                dispatch('getPageChildren', response.data.id)
                return Promise.resolve(response.data);
              },
              error => {
                commit('getPageFailure');
                return Promise.reject(error);
              }
            )
          }
          else
          {
            commit('getPageSuccess', response.data);
            dispatch('getPageChildren', response.data.id)
            return Promise.resolve(response.data);
          }
        },
        error => {
          commit('getPageFailure');
          return Promise.reject(error);
        }
      );
    },

    // TODO: This doesn't really need to be an action and is just a wrapper for calling the wagtail service from a component
    // I can't quite figure out how to make the component successfully call the service without errors, so here we are
    // Future JS wizard, solve this :D
    getPageByUrl(context, url) {
      return WagtailService.getPageByUrl(url).then(
        response => {
          return Promise.resolve(response.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },

    getPageChildren({ commit }, id) {
      return WagtailService.getPageChildren(id).then(
        response => {
          commit('getPageChildrenSuccess', response.data);
          return Promise.resolve(response.data);
        },
        error => {
          commit('getPageChildrenFailure');
          return Promise.reject(error);
        }
      );
    },

    getPopup({ commit }, page_id) {
      return WagtailService.getPageById(page_id).then(
        response => {
          commit('getPopupSuccess', response.data);
          return Promise.resolve(response.data);
        },
        error => {
          commit('getPopupFailure');
          return Promise.reject(error);
        }
      );
    },

    savePage({ state }) {
      let data = { title: "Hello There" }
      return WagtailService.savePage(state.currentPage.id, data).then(
        response => {
          return Promise.resolve(response.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },

    setPageTitle({ commit }, data) {
      commit('setPageTitle', data)
    },

    addKeyStat({ commit }, data) {
      commit('addKeyStat', data)
    },
    removeKeyStat({ commit }, index) {
      commit('removeKeyStat', index)
    },
    setAccessibiltyModeFlag({commit}, value) {
      commit('setAccessibilityMode', value)
    },


  },

  mutations: {
    getCommentsSuccess(state, data) {
      state.mapComments = data;
    },
    getCommentsFailure(state) {
      state.mapComments = [];
    },
    addComment(state, data) {
      state.newComment = data;
    },
    saveComment(state) {
      if (state.newComment !== undefined)
        state.mapComments.push( state.newComment );
      state.newComment = undefined
    },
    cancelComment(state) {
      state.newComment = undefined
    },
    getEsriTokenSuccess(state, data) {
      state.esriTokens = data;
    },
    getEsriTokenFailure(state) {
      state.esriTokens = [];
    },
    getSiteSettingsSuccess(state, data) {
      state.siteSettings = data;
    },
    getSiteSettingsFailure(state) {
      state.siteSettings = {};
    },
    getSiteStructureSuccess(state, data) {
      state.siteStructure = data.items;
    },
    getSiteStructureFailure(state) {
      state.siteStructure = [];
    },
    getPageSuccess(state, data) {
      state.currentChildren = [];
      state.currentPage = data;
      if (state.currentPage.meta.type === 'engage_virtualrooms.VirtualRoomPage')
        state.showHeader = false
      else
        state.showHeader = true
    },
    getPageFailure(state) {
      state.currentChildren = [];
      state.currentPage = undefined;
    },
    getPageChildrenSuccess(state, data) {
      state.currentChildren = data.items;
    },
    getPageChildrenFailure(state) {
      state.currentChildren = [];
    },
    getPopupSuccess(state, data) {
      state.currentPopup = data;
    },
    getPopupFailure(state) {
      state.currentPopup = undefined;
    },
    setPageTitle(state, data) {
      state.currentPage.title = data
    },
    addKeyStat(state, data) {
      state.currentPage.key_stats.push(data)
    },
    removeKeyStat(state, index) {
      state.currentPage.key_stats.pop(index)
    },
    setMapTool(state, value) {
      state.activeTool = value
    },
    showLegend(state, value) {
      state.showLegend = value
    },
    setLayerFilter(state, value) {
      state.layerFilter = value
    },
    setLayerOptions(state, value)
    {
      if (value.data === null)
        Vue.delete(state.layerOptions, value.key)
      else
        Vue.set(state.layerOptions, value.key, value.data)
    },
    setAccessibiltyMode(state, value){
      state.accessibilityMode = value
    },

  }
};
