<template>
  <ParagraphBlock
    v-if="block.type === 'paragraph'"
    :html="block.value"
  ></ParagraphBlock>
  <HeadingBlock
    v-else-if="block.type === 'heading'"
    :title="block.value"
  ></HeadingBlock>
  <AlertBlock
    v-else-if="block.type === 'alert'"
    :text="block.value"
  ></AlertBlock>
  <ColoredNotificationBlock
    v-else-if="block.type === 'colored_notification'"
    :data="block.value"
  ></ColoredNotificationBlock>
  <ImageBlock
    v-else-if="block.type === 'image'"
    :url="block.value.file"
    :width="block.value.width"
    :height="block.value.height"
  ></ImageBlock>
  <QuoteBlock
    v-else-if="block.type === 'quote'"
    :text="block.value"
  ></QuoteBlock>
  <DocumentBlock
    v-else-if="block.type === 'document'"
    :title="block.value.title"
    :url="block.value.file"
  ></DocumentBlock>
  <VideoBlock
    v-else-if="block.type === 'video'"
    :url="block.value.file"
    :thumbnail="block.value.thumbnail"
  ></VideoBlock>
  <LayerFilterSliderBlock
    v-else-if="block.type === 'layerFilterSlider'"
    :sliderData="block.value"
  >
  </LayerFilterSliderBlock>
  <LayerRadioGroupBlock
    v-else-if="block.type === 'layerRadioGroup'"
    :groupData="block.value"
  >
  </LayerRadioGroupBlock>
  <CardGalleryBlock
    v-else-if="block.type === 'cardgallery'"
    :data="block.value"
  ></CardGalleryBlock>
  <CardGalleryBlock
    v-else-if="block.type === 'collection_card_gallery'"
    :data="block.value[0]"
  ></CardGalleryBlock>
  <EmbeddedHtmlBlock
    v-else-if="block.type === 'embedded_html'"
    :data="block.value"
  ></EmbeddedHtmlBlock>
  <ButtonLinkRowBlock v-else-if="block.type === 'button_link_row'" :data="block.value"></ButtonLinkRowBlock>
  <OneColumnBlock v-else-if="block.type === 'one_column'" :data="block.value"></OneColumnBlock>
  <TwoColumnBlock v-else-if="block.type === 'two_columns'" :data="block.value"></TwoColumnBlock>
  <ThreeColumnBlock v-else-if="block.type === 'three_columns'" :data="block.value"></ThreeColumnBlock>
  <FourColumnBlock v-else-if="block.type === 'four_columns'" :data="block.value"></FourColumnBlock>
  <PrimaryLeftColumnBlock v-else-if="block.type === 'primary_left_column'" :data="block.value"></PrimaryLeftColumnBlock>
  <PrimaryRightColumnBlock v-else-if="block.type === 'primary_right_column'" :data="block.value"></PrimaryRightColumnBlock>
  <AccordianBlock v-else-if="block.type === 'accordian'" :data="block.value"></AccordianBlock>
  <ContainerBlock v-else-if="block.type === 'container'" :data="block"></ContainerBlock>
  <FullWidthHeaderBlock v-else-if="block.type === 'full_width_header'" :headerTitle="headerTitle" :data="block"></FullWidthHeaderBlock>


</template>

<script>
import ParagraphBlock from "./paragraphBlock.vue";
import AlertBlock from "./alertBlock.vue";
import HeadingBlock from "./headingBlock.vue";
import ImageBlock from "./imageBlock.vue";
import QuoteBlock from "./quoteBlock.vue";
import DocumentBlock from "./documentBlock.vue";
import VideoBlock from "./videoBlock.vue";
import LayerFilterSliderBlock from "./layerFilterSliderBlock.vue";
import LayerRadioGroupBlock from "./layerRadioGroupBlock.vue";
import CardGalleryBlock from "./cardGalleryBlock.vue";
import OneColumnBlock from "./columns/oneColumnBlock.vue"
import TwoColumnBlock from "./columns/twoColumnBlock.vue"
import ThreeColumnBlock from "./columns/threeColumnBlock.vue"
import FourColumnBlock from "./columns/fourColumnBlock.vue"
import PrimaryLeftColumnBlock from "./columns/primaryLeftColumnBlock.vue"
import PrimaryRightColumnBlock from "./columns/primaryRightColumnBlock.vue"
import AccordianBlock from"./accordianBlock.vue"
import EmbeddedHtmlBlock from './embeddedHtmlBlock.vue'
import ButtonLinkRowBlock from "./buttonLinkRowBlock.vue"
import ColoredNotificationBlock from "./coloredNotificationBlock.vue"

export default {
  name: "StreamfieldBlock",
  props: ["block", "headerTitle"],
  components: {    
    ParagraphBlock,
    AlertBlock,
    HeadingBlock,
    ImageBlock,
    QuoteBlock,
    DocumentBlock,
    ColoredNotificationBlock,
    VideoBlock,
    LayerFilterSliderBlock,
    LayerRadioGroupBlock,
    CardGalleryBlock,
    EmbeddedHtmlBlock,
    TwoColumnBlock,
    ThreeColumnBlock,
    FourColumnBlock,
    ContainerBlock: () => import('./containerBlock.vue'),
    FullWidthHeaderBlock: () => import('./fullWidthHeaderBlock.vue'),
    PrimaryLeftColumnBlock,
    PrimaryRightColumnBlock,
    AccordianBlock,
    OneColumnBlock,
    ButtonLinkRowBlock,

  },
  computed: {
    isColumnBlock() {
      return this.block.type.includes('_column')
    }
  },
  mounted(){
    console.log(this.block , "blocker")
  }
};
</script>

<style scoped>
</style>