<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>{{pageData.title}}</h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Page",
  props: ["pageData", "pageChildren"],
  data: () => ({}),
  mounted() {},
  computed: {},
  methods: {},
};
</script>

<style scoped>
</style>