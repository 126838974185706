import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VHover } from 'vuetify/lib/components/VHover';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"640px","height":"100vh","content-class":"landing_streamfield_dialog"},model:{value:(_vm.showLanding),callback:function ($$v) {_vm.showLanding=$$v},expression:"showLanding"}},[_c(VCard,{staticClass:"welcome_lightbox",staticStyle:{"overflow":"hidden"}},[_c('FocusLoop',{attrs:{"is-visible":_vm.showLanding,"auto-focus":true}},[_c(VCardText,{staticClass:"pt-5 pb-0"},[_c(VContainer,{staticClass:"pt-0 pb-0"},_vm._l((_vm.data.landing_streamfield),function(block,i){return _c(VRow,{key:i},[_c(VCol,[_c('StreamfieldBlock',{attrs:{"block":block}})],1)],1)}),1)],1),_c(VCardActions,[_c(VContainer,{staticClass:"pt-0 pb-0"},[_c(VRow,{attrs:{"justify":"center"}},[_vm._l((_vm.data.landing_shortcuts),function(block,i){return _c(VCol,{key:i,attrs:{"cols":"6","sm":"4","md":"4","lg":"4","xl":"4"}},[_c('PageShortcutBlock',{attrs:{"icon":block.value.page.menu_icon,"title":block.value.caption},on:{"click":function($event){return _vm.shortcutClicked(block.value.page.detail_url)}}})],1)}),_c(VCol,{attrs:{"cols":"6","sm":"4","md":"4","lg":"4","xl":"4"}},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{staticClass:"mx-auto transition-swing",class:("elevation-" + (hover ? 6 : 0)),attrs:{"flat":"","tabindex":"-1"},on:{"click":function($event){return _vm.welcomeClosed()}}},[_c(VCardText,{staticClass:"pb-0 text-center"},[_c(VBtn,{staticClass:"streamfield-btn",attrs:{"tabindex":"0","fab":"","x-large":"","color":"secondary"}})],1),_c(VCardText,{staticClass:"text-center subtitle-1"},[_vm._v(" Explore the "+_vm._s(_vm.pageType)+" ")])],1)]}}])})],1)],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }