<template>
  <v-app class="pl-4 pr-4" tabindex="-1" id="inspire">
    <div class="engage-header" tabindex="-1">
      <v-navigation-drawer
        app
        temporary
        :value="isHomeNavigationDrawerOpen"
        @input="TOGGLE_HOME_NAVIGATION_DRAWER"
        width="300"
      >
        <div
          id="header"
          role="banner"
          class="pl-4 pr-4 primary-logo"
          :style="{ background: $vuetify.theme.themes.light.primary }"
        >
          <v-img width="100%" height="96px" contain :src="siteLogo" />
        </div>
        <div id="nav" role="navigation" aria-label="Primary" class="app-menu">
          <v-list nav dense>
            <v-list-item
              v-for="(menuItem, i) in siteStructure"
              :key="i"
              :to="menuItem.pathname"
              link
              :style="navStyle(menuItem)"
              color="secondary"
            >
              <v-list-item-icon class="mr-4">
                <v-icon>{{ menuItem.menu_icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click.prevent="logOut">
              <v-list-item-icon class="mr-4">
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
        <div
          class="pl-4 pr-4 secondary-logo"
          :style="{ background: $vuetify.theme.themes.light.primary }"
        >
          <v-img width="100%" height="100%" contain :src="secondaryLogo" />
        </div>
      </v-navigation-drawer>

      <!-- <v-app-bar
        app
        fixed
        :color="showHeader ? 'white' : 'transparent'"
        :flat="!showHeader"
        height="96px"
        :class="'pa-0 ' + appBarClass"
      >
        <v-container fluid class="pa-0 ma-0 header-container">
          <v-row tabindex="-1" align="center" class="header-row">
            
            <div v-if="!showHeader">
              <v-btn
                tabindex="-1"
                fab
                small
                class="use-mouse ml-9 mr-9"
                @click="drawer = !drawer"
              >
                <v-icon>mdi-menu</v-icon>
              </v-btn>
              <v-btn
                tabindex="-1"
                fab
                small
                class="use-mouse accessibility-menu-btn"
                @click="accessibilityModeActive()"
              >
                <v-icon> mdi-human </v-icon>
              </v-btn>
            </div>

            <div v-else>
              <v-app-bar-nav-icon
                class="clickable ml-8 mr-8"
                @click.stop="drawer = !drawer"
              ></v-app-bar-nav-icon>
              <v-button
                flat
                class="clickable"
                @click="accessibilityModeActive()"
                ><v-icon> mdi-human </v-icon></v-button
              >
            </div>
            <img class="site-logo img-shadow" :src="siteLogo" />

            
          </v-row>
        </v-container>
      </v-app-bar> -->
    </div>

    <v-main
      id="content"
      role="main"
      tabindex="-1"
      :class="showHeader ? '' : 'pt-0'"
    >
      <router-view> </router-view>
    </v-main>

    <!-- <v-footer color="primary" app>
      <span class="white--text"
        >&copy; {{ new Date().getFullYear() }} - Powered by GHD Engage</span
      >
    </v-footer> -->
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  props: {
    source: String,
  },

  data: () => ({
    drawer: false,
    noHeader: true,
    customFont: null,
  }),
  components: {},
  mounted() {
    console.log(this.data)
    // Get the site data
    this.$store.dispatch("wagtail/getSiteSettings").then((response) => {
      console.log(response, "response from app.vue");
      if (response.primary_colour !== undefined)
        this.$vuetify.theme.themes.light.primary = response.primary_colour;
      if (response.secondary_colour !== undefined)
        this.$vuetify.theme.themes.light.secondary = response.secondary_colour;
      if (response.favicon !== undefined) {
        const favicon = document.getElementById("favicon");
        favicon.href = response.favicon;
      }
      this.customFont = response.custom_font;
      if (this.customFont !== null) {
        require("../public/css/variables.scss");
      }
      this.customCss = response.custom_css
      console.log(this.customCss, "custom")
      if (this.customCss !== null){
        //appends a new style sheet uploaded from wagtail to the vue app component
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = this.customCss;
        document.head.appendChild(style);
      }
    });

    

    this.$store.dispatch("wagtail/getSiteStructure");
  },
  methods: {
    ...mapActions("toolbar", ["TOGGLE_HOME_NAVIGATION_DRAWER"]),
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    accessibilityModeActive() {
      console.log("activated");
      if (this.accessibilityMode == true) {
        this.accessibilityFlag = false;
        this.$store.commit(
          "wagtail/setAccessibiltyMode",
          this.accessibilityFlag,
          { root: true }
        );
        console.log(this.accessibilityFlag, "access flag");
      } else {
        this.accessibilityFlag = true;
        this.$store.commit(
          "wagtail/setAccessibiltyMode",
          this.accessibilityFlag,
          { root: true }
        );
        console.log(this.accessibilityFlag, "access flag");
      }
    },
    navStyle(menuItem) {
      let style = "";
      style += "padding-left:" + (16 + 12 * menuItem.depth) + "px";
      return style;
    },
  },
  computed: {
    ...mapState("toolbar", ["isHomeNavigationDrawerOpen"]),
    currentUser() {
      return this.$store.state.auth.user;
    },
    siteStructure() {
      let siteStructure = [];
      for (const item of this.$store.state.wagtail.siteStructure) {
        //TODO: Unsure how reliable this is.  Looks at the URL to calculate depth by counting slashes in the URL
        item.depth = item.meta.html_url.split("/").length - 4;
        let url = new URL(item.meta.html_url);
        item.pathname = url.pathname;
        item.menutitle = Array(item.depth + 1).join(" -") + " " + item.title;
        siteStructure.push(item);
      }

      return siteStructure;
    },
    appBarClass() {
      if (!this.showHeader) return "transparent-header";
      return "normal-header";
    },
    siteLogo() {
      return this.$store.state.wagtail.siteSettings.site_logo;
    },
    secondaryLogo() {
      return this.$store.state.wagtail.siteSettings.secondary_logo;
    },
    showHeader() {
      return this.$store.state.wagtail.showHeader;
    },
    isLoggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    pageTitle() {
      return this.$store.state.wagtail.currentPage.title;
    },
    accessibilityMode() {
      return this.$store.state.wagtail.accessibilityMode;
    },
  },
  watch: {
    isLoggedIn() {
      this.$store.dispatch("wagtail/getSiteStructure");
    },
  },
};
</script>

<style lang="scss" scoped>


.side-by-side {
  display: inline-flex;
}

.header {
  height: 96px;
}

.menu-hamburg {
  display: none;
}

@media (max-width: 600px) {
  .logo-hidden {
    display: none;
  }
}

@media (max-width: 1400px) {
  .menu-hidden {
    display: none;
  }

  .menu-hamburg {
    display: block;
  }
}

.transparent-header {
  pointer-events: none;
  margin: 0px;
  padding: 0px;
}

.normal-header {
}

.pass-thru {
  pointer-events: none;
}

.clickable {
  pointer-events: all;
}

.logo-background {
  background-color: #1c3c52;
}

.use-mouse {
  pointer-events: all;
}

.engage-header >>> .v-toolbar__content {
  padding: 0px !important;
}

.header-container {
  height: 100%;
}

.header-row {
  height: 100%;
}

.site-logo {
  max-height: 100%;
}

.app-menu {
  position: absolute;
  top: 96px;
  bottom: 96px;
  width: 100%;
  overflow-y: auto;
}

.primary-logo {
  position: absolute;
  top: 0px;
  height: 96px;
  width: 100%;
}

.secondary-logo {
  position: absolute;
  height: 96px;
  bottom: 0px;
  width: 100%;
}

.header-page-title {
  text-shadow: 1px 1px 2px #222;
}

.img-shadow {
  -webkit-filter: drop-shadow(1px 1px 1px #222);
  filter: drop-shadow(1px 1px 1px #222);
}

::v-deep .v-dialog__content{
  /* align-self: flex-start; */
  height: 100vh !important;
}
</style>
