import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{staticClass:"mx-auto transition-swing",class:("elevation-" + (hover ? 6 : 0)),attrs:{"flat":"","tabindex":"-1"},on:{"click":function($event){return _vm.$emit('click')}}},[_c(VCardText,{staticClass:"pb-0 text-center"},[_c(VBtn,{staticClass:"streamfield-btn",attrs:{"tabindex":"0","fab":"","x-large":"","color":"secondary"}},[_c(VIcon,{attrs:{"x-large":""}},[_vm._v(_vm._s(_vm.icon))])],1)],1),_c(VCardText,{staticClass:"text-center subtitle-1"},[_vm._v(" "+_vm._s(_vm.title)+" ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }