<template>

  <LightboxContentLayout :accessibilityText="data.accessibility_text" :ariaDescription="undefined"
    :captionText="data.caption" :accessibilityTextFlag="false">
    
  <v-container class="container">
    <v-row align="center" justify="center" class="row">
      <v-col class="col" :cols="cols" :sm="sm" :md="md" :lg="lg" :xl="xl">
        <v-card light>
          <!-- <v-toolbar dense dark color="primary">
            <v-toolbar-title>{{pageData.title}}</v-toolbar-title>
          </v-toolbar> -->
          <v-card-text>
            <WebPageLegacy
              v-if="pageData !== undefined"
              :pageData="pageData"
              fill-width
              :showTitle="true"
              :showHeader="false"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  </LightboxContentLayout>




</template>

<script>
import WebPageLegacy from "../../views/wagtail/WebPageLegacy.vue";
import LightboxContentLayout from './LightboxContentLayout.vue';


export default {
  name: "LightboxWagtailPageStruct",
  data: () => ({
    pageData: undefined,
  }),
  props: ["data"],
  components: { WebPageLegacy, LightboxContentLayout },
  mounted() {
    // Ajax request the page content
    // Once page content is established, render page
    console.log(this.data.page.meta.detail_url);
    let self = this;
    this.$store
      .dispatch("wagtail/getPageByUrl", this.data.page.meta.detail_url)
      .then((response) => {
        self.pageData = response;
      });
  },
  computed: {
    pageTitle() {
      if (this.pageData != undefined) return this.pageData.title;
      return "";
    },
    sm() {
      return 12
    },
    md() {
      return this.data.half_width ? 10 : 12
    },
    lg() {
      return this.data.half_width ? 8 : 12
    },
    xl() {
      return this.data.half_width ? 6 : 12
    },
    cols() {
      return 12
    }
  },
};
</script>


<style scoped>

.container {
  padding: 0px;
  /* margin: 0px; */
  width: 100%;
  height: 100%;
}

.row {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
}

.col {
  padding: 0px;
  margin: 0px;
  max-height: 100%;
  overflow-y: auto;
}

</style>