<template>
  <div>
    <blockquote class="blockquote">{{ text }}</blockquote>
  </div>
</template>

<script>
export default {
  name: "QuoteBlock",
  props: ["text"],
};
</script>

<style scoped>
</style>