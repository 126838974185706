<template>
  <div>
    <div tabindex="-1" v-if="isMounted">
      <!-- <slot /> -->
    </div>
    <div class="map-html-overlays">
      <slot />
    </div>
  </div>
</template>

<script>
import { loadModules } from "esri-loader";
// import { extractFilenameFromHeader } from "../../../dist/pdfjs/build/pdf";
// import ApiService from "../services/api.service"

export default {
  name: "MapArc",
  components: {},
  data: () => ({
    isMounted: false,
  }),
  props: [
    "mapZoom",
    "mapMinZoom",
    "mapMaxZoom",
    "mapCenter",
    "bottomLeftExtent",
    "topRightExtent",
    "portalItemId",
    "portalItemType",
    "filterJson",
    "layerOptions",
    "tokens",
    "basemapWidget",
    "showLegend",
  ],
  provide() {
    const self = this;
    return {
      get map() {
        return self.map;
      },
      get markerLayer() {
        return self.markerLayer;
      },
      get view() {
        return self.view;
      },
    };
  },

  created() {
    console.log("MapCreated");
    this.maps = [];
  },

  mounted() {
    console.log("MapMounted");
    // console.log( "ultima", this.bottomLeftExtent,
    // this.topRightExtent, Number(this.topRightExtent.x))
    this.view = undefined;
    this.map = undefined;
    this.markerLayer = undefined;
    // this.tokens = this.$store.state.map.esriTokens;

    // Get ArcGIS Tokens
    // ApiService.getEsriToken().then((response) => {

    // this.$store.state.map.tokens = response.data

    // lazy load the required ArcGIS API for JavaScript modules and CSS
    loadModules(
      [
        "esri/WebMap",
        "esri/views/MapView",
        "esri/WebScene",
        "esri/views/SceneView",
        "esri/identity/IdentityManager",
        "esri/widgets/Search",
        "esri/widgets/Expand",
        "esri/widgets/Legend",
        "esri/widgets/BasemapGallery",
        "esri/core/watchUtils",
      ],
      {
        css: true,
      }
    ).then(
      ([
        WebMap,
        MapView,
        WebScene,
        SceneView,
        IdentityManager,
        Search,
        Expand,
        Legend,
        BasemapGallery,
        watchUtils,
      ]) => {
        let self = this;
        
        console.log(this.mapZoom);
        // Firstly any tokens for ArcGIS
        this.tokens.forEach(function (token) {
          console.log("TOKEN!!!");
          console.log({
            token: token.token,
            server: token.server_url,
          });
          IdentityManager.registerToken({
            token: token.token,
            server: token.server_url,
          });
        });

        console.log("portalItemId: " + this.portalItemId);
        console.log("portalItemType: " + this.portalItemType);
  
        if (this.portalItemType == "webscene") {
          if (this.portalItemId !== null && this.portalItemId !== undefined) {
            this.map = new WebScene({
              portalItem: {
                id: this.portalItemId,
              },
            });
          } else {
            this.map = new WebScene({
              basemap: "topo",
              ground: "world-elevation",
            });
          }

          // Create the scene view
          this.view = new SceneView({
            container: this.$el,
            map: this.map,
            zoom: this.mapZoom,
            center: this.mapCenter,
            // extent
          });
          this.view.constraints = {
            minZoom: this.mapMinZoom,
            maxZoom: this.mapMaxZoom
          };
        } //Just create a regular map if webscene isn't specified
        else {
          if (this.portalItemId !== null && this.portalItemId !== undefined) {
            this.map = new WebMap({
              portalItem: {
                id: this.portalItemId,
              },
            });
          } else {
            this.map = new WebMap({
              basemap: "topo",
            });
          }
        
          // Create the scene view
        if (this.bottomLeftExtent !== null || this.topRightExtent !== null){
          this.view = new MapView({
            container: this.$el,
            map: this.map,
            zoom: this.mapZoom,
            center: this.mapCenter,
          });
          this.view.constraints = {
            geometry: { 
              type: "extent",
              xmin: Number(this.bottomLeftExtent.x),
              ymin:  Number(this.bottomLeftExtent.y),
              xmax: Number(this.topRightExtent.x),
              ymax:   Number(this.topRightExtent.y)
            },
            minZoom: this.mapMinZoom,
            maxZoom: this.mapMaxZoom
          };
        }else {
                    this.view = new MapView({
            container: this.$el,
            map: this.map,
            zoom: this.mapZoom,
            center: this.mapCenter,
          });
          this.view.constraints = {
            minZoom: this.mapMinZoom,
            maxZoom: this.mapMaxZoom
          };
        }
        }

        this.view.ui.move(
          ["zoom", "navigation-toggle", "compass"],
          "top-right"
        );
        // this.view.popup.autoOpenEnabled = false;
        this.view.popup.autoCloseEnabled = true;
        this.view.popup.dockEnabled = false;
        this.view.popup.actions = [];
        this.view.popup.dockOptions = { buttonEnabled: false };

        // Change the cursor to pointer if we're hovering a marker
        this.view.on("pointer-move", function (evt) {
          var screenPoint = {
            x: evt.x,
            y: evt.y,
          };
          self.view.hitTest(screenPoint).then(function (response) {
            self.$el.style.cursor = "default";
            if (response.results.length > 0)
              if (response.results[0].graphic.layer === self.markerLayer)
                self.$el.style.cursor = "pointer";
          });
        });

        this.view.on("click", function (evt) {
          var screenPoint = {
            x: evt.x,
            y: evt.y,
          };

          console.log(evt.mapPoint.longitude);
          console.log(evt.mapPoint.latitude);

          self.view.hitTest(screenPoint).then(function (response) {
            if (
              response.results.length === 0 &&
              self.$store.state.mapTool === "comment"
            ) {
              self.$store.dispatch("map/placeComment", [
                evt.mapPoint.longitude,
                evt.mapPoint.latitude,
              ]);
            }
          });
        });

        // Fire an event when the map changes it's focus
        watchUtils.watch(self.view, "extent", function () {
          self.$emit("mapMoved", self.view.extent);
          self.$emit("mapZoomed", self.view.zoom)
          
        });

        //Add the default search widget
        const search = new Search({
          view: this.view,
        });
        this.view.ui.add(search, "top-left");


        // Add the legend widget

        const legend = new Expand({
          content: new Legend({
            view: this.view,
          }),
          view: this.view,
          expanded: this.showLegend,
        });
        this.view.ui.add(legend, "bottom-left");

        // Add the basemap widget
        const basemapGallery = new Expand({
          content: new BasemapGallery({
            view: this.view,
          }),
          view: this.view,
          expanded: false,
        });

        if (this.basemapWidget) {
          this.view.ui.add(basemapGallery, "bottom-right");
        }

        this.isMounted = true;
        self.$emit("mapLoadEnd");
        this.$emit("ready");
      }
    );
    // });
  },
  methods: {
    zoomToCenter: function (center, zoom) {
      this.view
        .goTo({
          center: center,
          zoom: zoom,
        })
        .catch(function (error) {
          if (error.name != "AbortError") {
            console.error(error);
          }
        });
    },
    zoomToPoints: function (pointArray) {
      if (pointArray.length === 0) return;

      let self = this;
      loadModules(["esri/geometry/Multipoint"], {
        css: true,
      }).then(([Multipoint]) => {
        let multiPoint = new Multipoint({ points: pointArray });
        self.view.goTo(multiPoint).catch(function (error) {
          if (error.name != "AbortError") {
            console.error(error);
          }
        });
      });
    },
    zoomToLocation: function (markerZoom, center) {
      let options = {
        duration: 2000,
        easing: "linear",
      };

      this.view.goTo(
        {
          target: center,
          zoom: markerZoom,
        },
        options
      );
    },
    applyFilters: function () {
      console.log("Updating layer filters");
      let self = this;
      self.view.layerViews.forEach(function (layerView) {
        layerView.filter = self.filterJson;
      });
    },
    applyOptions: function () {
      console.log("Updating layer options");
      let self = this;
      self.view.map.layers.forEach(function (layer) {
        if (layer.title in self.layerVisibility) {
          layer.visible = self.layerVisibility[layer.title];
        }
      });
    },
  },
  computed: {
    layerVisibility() {
      let layers = {};
      let layerOptions = this.layerOptions;

      //Build a list of layers and their visibility status
      for (let group in layerOptions) {
        for (let layer in layerOptions[group]) {
          let visible = layerOptions[group][layer];
          layers[layer] = visible;
        }
      }
      return layers;
    },
  },
  watch: {
    filterJson: function () {
      this.applyFilters();
    },
    layerOptions: function () {},
    layerVisibility: function () {
      this.applyOptions();
    },
  },
};
</script>

<style scoped>
div {
padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

.marker {
  position: absolute;
  /* top: 0px; */
  /* left: 0px; */
}

.centered-block {
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  position: absolute;
}

.esri-popup__main-container {
  max-width: 300px;
}

.esri-view-height-less-than-medium .esri-popup__main-container {
  max-height: 200px;
}
.esri-view-width-xlarge .esri-popup__main-container {
  width: 140px;
}

.esri-view-width-medium .esri-popup__main-container {
  width: 140px;
}

.map-html-overlays {
  position:absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  pointer-events: None;
}

>>> .esri-ui {
  z-index: 2;
}

</style>