<template>
    <div>
      <div class="text-h4">
        {{title}}
      </div>
    </div>
</template>

<script>
export default {
  name: "HeadingBlock",
  props: ['title']
};
</script>

<style scoped>
</style>