<template>
  <v-dialog persistent max-width="640px" height="100vh" v-model="showLanding" style="" content-class="landing_streamfield_dialog">
      <v-card class="welcome_lightbox" style="overflow: hidden">
        <FocusLoop :is-visible="showLanding" :auto-focus="true">
          <v-card-text class="pt-5 pb-0">
            <v-container class="pt-0 pb-0">
              <v-row
                v-for="(block, i) in data.landing_streamfield"
                :key="i"
              >
                <v-col>
                  <StreamfieldBlock :block="block" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-container class="pt-0 pb-0">
              <v-row justify="center">
                <v-col
                  v-for="(block, i) in data.landing_shortcuts"
                  :key="i"
                  cols="6"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                >
                
                  <PageShortcutBlock
                    :icon="block.value.page.menu_icon"
                    :title="block.value.caption"
                    @click="shortcutClicked(block.value.page.detail_url)"
                  />
                  
                </v-col>
                <v-col
                    cols="6"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4">
                        <v-hover v-slot:default="{ hover }">
                    <v-card
                      flat
                      @click="welcomeClosed()"
                      :class="`elevation-${hover ? 6 : 0}`"
                      class="mx-auto transition-swing"
                      tabindex="-1"
                    >
                      <v-card-text class="pb-0 text-center">
                        <v-btn
                          class="streamfield-btn"
                          tabindex="0"
                          fab
                          x-large
                          color="secondary"
                        >
                          <!-- <v-icon x-large>mdi-map</v-icon> -->
                        </v-btn>
                      </v-card-text>
                      <v-card-text class="text-center subtitle-1">
                        Explore the {{pageType}}
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
              
            </v-container>
          </v-card-actions>
        </FocusLoop>
      </v-card>
    </v-dialog>


</template>

<script>
import StreamfieldBlock from "../../components/blocks/streamfieldBlock.vue";
import PageShortcutBlock from "../../components/blocks/pageShortcutBlock.vue";
import { FocusLoop } from "@vue-a11y/focus-loop";
import * as arrowKeyNavigation from "arrow-key-navigation";

//enables next & previous tab style DOM navigation using arrow keys
arrowKeyNavigation.register();

export default {
  name: "LandingStreamfield",
  props: [ "pageType", "data"],
  data: () => ({showLanding: true}),
  components: {StreamfieldBlock, PageShortcutBlock, FocusLoop},
  computed: {
  },
  // beforeCreate: function () {
  //   if (sessionStorage.getItem("showLanding") == "false") {
  //     this.showLanding = false;
  //   } else if (this.pageData.description_info.length >= 1)
  //     this.showLanding = true;
  // },
  mounted(){
      let html =  document.querySelector('html');
      html.style.overflow = 'hidden'

    console.log(this.landing_shortcuts, this.landing_streamfield , "landingstream")
      // Saves popup state to popup landing
    // if (sessionStorage.getItem("showLanding") == "false" || this.landing_shortcuts >= 0 ) {
    //   this.showLanding = false;
    // } else if (this.pageData.description_info.length >= 1)
    //   this.showLanding = true;
    if (this.data.landing_streamfield.length <= 1){
      this.showLanding = true
    }else this.showLanding = false

    console.log(this.data, "test data")
  },

  methods: {
        shortcutClicked(url) {
            console.log(url, "url")
      //When we click a shortcut, we either route to that page, or if the shortcut is the current page we close the dialog
      let pagePath = new URL(url).pathname;
      let currPath = this.currentPath;
      this.showLanding = false;

      if (currPath === pagePath) this.welcomeClosed();
      else this.$router.push(pagePath);

    //   const firstFocusableElement =
    //     document.querySelectorAll("#hotspot-img")[0];
    //   firstFocusableElement.focus();
    },
    welcomeOpen(){
      // let currSession = sessionStorage.getItem("showLanding")
     if (sessionStorage.getItem("showLanding") == "false" && this.pageType !== 'Room') {
      this.showLanding = false;}
      else this.showLanding = true
    },
    welcomeOpenEngagementRoom(){
        this.showLanding = true
    },
    welcomeClosed() {
      this.showLanding = false;
      console.log("destory landing")
      sessionStorage.setItem("showLanding", false);
    if (this.pageType == 'Room'){
      let html =  document.querySelector('html');
      html.style.overflow = 'hidden'
    }else {
      let html =  document.querySelector('html');
      // html.style.overflowY = 'scroll'
            html.style.overflowX = 'hidden'

    }
    //   this.$nextTick(() => {
    //     if (this.accessibilityMode == false) {
    //       const elements = document.querySelectorAll("#hotspot-img");
    //       elements[0].focus();
    //     }
    //   });
    }
    
  },
    watch: {
      showLanding(newVal){
              let html =  document.querySelector('html');

        console.log( "showlanding", newVal)
        if (!newVal && this.pageType !== 'Room'){
          console.log("newVal is false")
          html.style.overflowY = 'scroll'
            html.style.overflowX = 'hidden'
            // html.style.scroll
        }
        else {
             html.style.overflowY = 'hidden'
            html.style.overflowX = 'hidden'

        }
      }
    }
  
};
</script>

<style scoped>

.lightbox-btn:focus-visible {
  outline: 4px dashed darkorange;
}
.streamfield-btn:focus-visible {
  outline: 4px dashed darkorange;
}

::v-deep .v-dialog__content{
  /* align-self: flex-start; */
  height: 100vh !important;
}
::v-deep #inspire > div.v-dialog__content.v-dialog__content--active{
  height: 100vh !important;
}
::v-deep #inspire > div.v-overlay {
  max-height: 100vh  !important;
}
</style>