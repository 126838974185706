<template>
  <div
    :style="{
      width: '100%',
      height: '100%',
    }"
  >
    <Marzipano typeOf="equirect" :data="data.panorama" />

    <div
      :style="{
        width: '100%',
        height: '15%',
      }"
      v-if="hasCaption"
      class="caption-div"
    >
      <v-container v-if="hasCaption" fluid class="pa-2 lb-caption">
        <v-row justify="center" align="center" class="fill-height row">
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="10"
            xl="8"
            class="caption-col"
            justify="center"
            align="center"
          >
            <div class="white--text">{{ data.caption }}</div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import Marzipano from "../panorama/Marzipano.vue";

export default {
  name: "LightboxPanoramaCard",
  data: () => ({
    dialog: true,
  }),
  props: ["data"],
  components: {
    Marzipano,
  },
  computed: {
    image() {
      if (this.data && this.data.file) return this.data.file;
      return undefined;
    },
    hasCaption() {
      if (this.data && this.data.caption)
        if (this.data.caption !== undefined) return true;
      return false;
    },
  },
};
</script>


<style scoped>
.lb-caption {
  position: relative;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.caption-col {
  overflow-y: auto;
  overflow-x: hidden;
}

.caption-div {
  overflow-y: auto;
  overflow-x: hidden;
}

.row {
  width: 100%;
  overflow-y: auto;
}

@media (max-width: 600px) {
}

.repeating-gradient {
  background-image: repeating-linear-gradient(
    -45deg,
    rgba(255, 0, 0, 0.25),
    rgba(255, 0, 0, 0.25) 5px,
    rgba(0, 0, 255, 0.25) 5px,
    rgba(0, 0, 255, 0.25) 10px
  );
}
</style>