<template>
  <div>
    <v-btn :maxWidth="200" class=" display: inline-block mb-0 lightbox-btn" block large color="secondary" dark @click="downloadFile">
      <span class = "cols-12 span-label d-inline-block text-truncate">  <span> <v-icon left>mdi-download</v-icon>{{title}}</span></span>
    </v-btn>
  </div>
  
</template>

<script>
export default {
  name: "DocumentBlock",
  props: ["title", "url"],
  methods: {
    downloadFile() {
      window.open(this.url)
    }
  }
};
</script>

<style scoped>
::v-deep .v-btn__content{
  max-width:100%
}
/* @media only screen and (max-width: 345px) {
 .span-label{
  max-width: 40% !important;
 }
}

@media only screen and (min-width:345px)  and (max-width: 600px) {
 .span-label{
  max-width: 70% !important;
 }
}

@media only screen and (min-width:600px) and (max-width: 960px) {
 .span-label{
  max-width: 100% !important;
 }
} */
</style>