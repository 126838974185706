<template>
  <v-toolbar dark :color="transparent ? 'transparent' : 'primary'" :elevation="transparent ? 0 : 2" :height="height" :class="transparent ? 'pass-thru' : 'clickable'">
    <v-app-bar-nav-icon
      dark
      class="clickable"
      @click="TOGGLE_HOME_NAVIGATION_DRAWER"
    ></v-app-bar-nav-icon>

    <v-app-bar-nav-icon
      dark
      id="accessibility-menu-btn"
      class="clickable accessibility-menu-btn"
         @click="accessibilityModeActive()"
    >
      <v-icon> mdi-human </v-icon>
    </v-app-bar-nav-icon>
    
    <v-img
      height="100%"
      :src="siteLogo"
      contain
      position="left"
    ></v-img>

    <v-toolbar-items aria-label="secondary" role="navigation" v-show="!transparent">
        <v-btn
          text
          v-for="item in siteStructure"
          :key="item.title"
          :to="item.pathname">
          <v-icon v-show="item.menu_icon == 'mdi-home'" left dark>{{ item.menu_icon }}</v-icon>
          <span  v-show="item.menu_icon !== 'mdi-home'">{{ item.title }} </span>
        </v-btn>
        <!-- <v-btn>test</v-btn> -->
      </v-toolbar-items>
  </v-toolbar>

</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "MenuLogoCard",
  props: ["data", "transparent", "height"],
  data: () => ({}),
  components: {},
  mounted (){
    console.log(this.siteStructure, "data from header")
        this.$store.dispatch("wagtail/getSiteStructure");

  },
  computed: {
    siteLogo() {
      return this.$store.state.wagtail.siteSettings.site_logo;
    },
      siteStructure() {
      let siteStructure = [];
      for (const item of this.$store.state.wagtail.siteStructure) {
        //TODO: Unsure how reliable this is.  Looks at the URL to calculate depth by counting slashes in the URL
        //Keep Icon for L0 no text, keep text no icon for L1
        if (item.depth == 0 || item.depth == 1){
          item.depth = item.meta.html_url.split("/").length - 4;
          let url = new URL(item.meta.html_url);
          item.pathname = url.pathname;
          item.menutitle = Array(item.depth + 1).join(" -") + " " + item.title;
          console.log(item,item.depth, "item depth")
          siteStructure.push(item);
        }
      }

      return siteStructure;
    },
  },
  methods: {
    ...mapActions("toolbar", ["TOGGLE_HOME_NAVIGATION_DRAWER"]),
        accessibilityModeActive() {
      console.log("activated");
      if (this.accessibilityMode == true) {
        this.accessibilityFlag = false;
        this.$store.commit(
          "wagtail/setAccessibiltyMode",
          this.accessibilityFlag,
          { root: true }
        );
        console.log(this.accessibilityFlag, "access flag");
      } else {
        this.accessibilityFlag = true;
        this.$store.commit(
          "wagtail/setAccessibiltyMode",
          this.accessibilityFlag,
          { root: true }
        );
        console.log(this.accessibilityFlag, "access flag");
      }
    },
  },
};
</script>

<style scoped>
.pass-thru {
  pointer-events: none;
}

.clickable {
  pointer-events: all;
}

.menu-btn {
  position: absolute;
  left: 0px;
  top: 0%;
  margin-top: 0px;
}

.site-logo {
  /* height:56px; */ 
}
</style>