import Vue from 'vue';
import Vuex from 'vuex';
import { auth } from './auth.module';
import { map } from './map.module';
import { wagtail } from './wagtail.module';
import { toolbar } from './toolbar.module';
import { lightbox } from './lightbox.module';


Vue.use(Vuex);

export default new Vuex.Store({
  modules: { auth, map, wagtail, toolbar, lightbox },
  state: {
    tokens: [],
    sitePage: "map",
    count: 0,
    activeTool: "map",
    activeToolData: undefined,
    showLegend: false,
    mapTool: undefined,
    mapZoom: 6.0,
    mapCenter: [150.87180567987266, -29.780386190387905],
    rootPage: 12,
    currentPage: undefined,
    currentMarker: undefined,
    currentComment: undefined,
    markers: [
    ],
    debugNoMarkers: true,
    debugNoLayers: true,
    addComment: true,
  },
  mutations: {
    setSitePage(state, value) {
      state.sitePage = value
    },
    increment(state) {
      state.count++
    },
    setTool(state, value) {
      state.activeTool = value;
      state.activeToolData = undefined;
    },
    setToolData(state, value) {
      state.activeToolData = value;
    },
    setLegend(state, value) {
      state.showLegend = value;
    },
    setMapTool(state, value) {
      state.mapTool = value;
    },
    setCategory(state, value) {
      state.selectedCategory = value;
      if (state.selectedCategory !== undefined) {
        state.markers = state.categoryData[state.selectedCategory]["markers"]
      }
      else {
        state.markers = []
      }
    },
    addMarker(state, value) {
      state.markers.push(value)
    },
    selectMarker(state, value) {
      state.currentMarker = value
      state.currentComment = undefined
      state.mapTool = undefined
    },
    selectComment(state, value) {
      state.currentComment = value
      state.currentMarker = undefined
      state.mapTool = undefined
    },
    setPage(state, data) {
      state.currentPage = data
      if (state.currentPage != undefined && state.currentPage != null)
        state.markers = state.currentPage.markers
      else
        state.markers = []
      state.currentComment = undefined
      state.currentMarker = undefined
      state.mapTool = undefined
    },
    setLayerVisible(state, data) {
      state.currentPage.layers[data.id] = data.visible
    },
    setTokens(state, data) {
      state.tokens = data
    }
  },
});
