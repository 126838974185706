<template>
  <v-container fill-height fluid>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6" lg="4" xl="3">
        <v-row justify="center" class="pl-6 pr-6">
          <v-scale-transition>
            <v-alert
              v-show="!loading && message !== ''"
              border="top"
              colored-border
              type="error"
              elevation="2"
              >{{ message }}</v-alert
            >
          </v-scale-transition>
          <v-card width="100%">
            <v-toolbar dark color="primary">
              <v-toolbar-title>Login</v-toolbar-title>
            </v-toolbar>
            <v-container class="pa-7">
              <v-row justify="center">
                <v-text-field
                  v-model="user.username"
                  label="Username"
                  placeholder="Username"
                  id="username"
                  required
                  outlined
                  dense
                  prepend-icon="mdi-account"
                ></v-text-field>
              </v-row>
              <v-row justify="center">
                <v-text-field
                  id="password"
                  v-model="user.password"
                  label="Password"
                  placeholder="Password"
                  required
                  outlined
                  dense
                  prepend-icon="mdi-lock"
                  type="password"
                ></v-text-field>
              </v-row>
              <v-row justify="center">
                <v-btn id="login-btn" color="primary" @click="handleLogin"> Login </v-btn>
              </v-row>
            </v-container>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Login",
  data: () => ({
    user: { username: "", password: "" },
    loading: false,
    message: "",
  }),
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    let message = urlParams.get("message");
    if (message !== undefined && message != null)
      this.message = message
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  methods: {
    handleLogin() {
      this.loading = true;

      if (this.user.username && this.user.password) {
        this.$store.dispatch("auth/login", this.user).then(
          () => {
            const urlParams = new URLSearchParams(window.location.search);
            const redirectPath = urlParams.get("redirect");
            if (redirectPath !== null) this.$router.push(redirectPath);
            else this.$router.push("/");
          },
          (error) => {
            this.loading = false;
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.detail) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
  },
};
</script>

<style scoped>
</style>