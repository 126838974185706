export const toolbar = {
  namespaced: true,
  state: {
    isHomeNavigationDrawerOpen: false,
  },
  actions: {
    TOGGLE_HOME_NAVIGATION_DRAWER(context, open) {
      context.commit('TOGGLE_HOME_NAVIGATION_DRAWER', open)
    },
  },
  mutations: {
    TOGGLE_HOME_NAVIGATION_DRAWER: (state, open) => {
      state.isHomeNavigationDrawerOpen = open
    },
  },
};
