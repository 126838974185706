import AuthService from './auth.service';
import apiUrl from './api-url'

const axiosApiInstance = AuthService.axiosApiInstance;

class MapService {
  getComments(mapPageId) {
    let params = {map: mapPageId}
    return axiosApiInstance.get(apiUrl() + 'api/map-comments/', params);
  }

  getPage(id) {
    return axiosApiInstance.get(apiUrl() + 'cms-api/pages/' + id + '/');
  }

  getEsriTokens() {
    return axiosApiInstance.get(apiUrl() + 'api/tokens/');
  }

  savePage(id, data) {
    console.log(data)
    return axiosApiInstance.patch(apiUrl() + 'api/map-pages/' + id + '/', {title: "Hello"})
  }
}

export default new MapService();
