<template>

  <LightboxContentLayout :accessibilityText="data.accessibility_text" :ariaDescription="ariaDescription"
    :captionText="data.caption" :accessibilityTextFlag="accessibilityTextFlag">

    <v-container fluid class="full-screen pa-0 ma-0">
      <v-row align="center" class="full-screen  pa-0 ma-0">
        <VueCompareImage style="text-align: center" :aria-describedby="this.ariaDescription" v-if="renderComponent"
          :sliderPositionPercentage="accessibleSliderValue" :leftImage="beforeImage" :rightImage="afterImage"
          leftLabel="Before" rightLabel="After" class="compare-image" />
      </v-row>
    </v-container>



  </LightboxContentLayout>

</template>

<script>
import VueCompareImage from "vue-compare-image";
import LightboxContentLayout from "./LightboxContentLayout.vue"

export default {
  name: "LightboxBeforeAfterCard",
  data: () => ({
    dialog: true,
    renderComponent: true,
    windowWidth: window.screen.width,
  }),
  props: [
    "data",
    "accessibleSliderValue",
    "accessibilityTextFlag",
    "ariaDescription",
  ],
  components: { VueCompareImage, LightboxContentLayout },
  mounted() {
  },
  computed: {
    beforeImage() {
      if (this.data && this.data.before.file) return this.data.before.file;
      return undefined;
    },
    afterImage() {
      if (this.data && this.data.after.file) return this.data.after.file;
      return undefined;
    }
  },
  methods: {
  },
  watch: {
    accessibleSliderValue(newVal) {
      console.log("Slider Value:", newVal);
      this.renderComponent = false;
      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
  },
};
</script>


<style scoped>
.full-screen {
  width: 100%;
  height: 100%;
}

.compare-image {
  /* max-height: 100%; */
}

>>>.left-label {
  color: white;
  background-color: rgba(0, 0, 0, 0.25);
  border-bottom-right-radius: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
}

>>>.right-label {
  color: white;
  background-color: rgba(0, 0, 0, 0.25);
  border-bottom-left-radius: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
}
</style>