import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageHeaderCard',{staticClass:"map-header",attrs:{"transparent":false}}),_c('div',{staticClass:"map-body"},[_c('Sidebar',{class:'map-sidebar',attrs:{"pageData":_vm.pageData}}),_c('Map',{class:'map',attrs:{"tabindex":"-1","pageData":_vm.pageData,"refs":"map"}})],1),_c('LandingStreamfield',{ref:"landing",attrs:{"pageType":'Map',"data":this.pageData}}),_c('LightboxGallery',{ref:"lightbox",attrs:{"showLightbox":_vm.lightboxContent.length > 0 && _vm.lightboxReady,"content":_vm.lightboxContent},on:{"closed":function($event){return _vm.closeLightbox()}}}),_c(VDialog,{directives:[{name:"show",rawName:"v-show",value:(_vm.accessibilityModalFlag2),expression:"accessibilityModalFlag2"}],ref:"accessibilityLightbox",attrs:{"content-class":"accessibility-lightbox","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }(_vm.accessibilityModalFlag2 = false),
        (_vm.accessibilityModalFlag = false),
        _vm.closeAccessibilityLightbox()}},scopedSlots:_vm._u([{key:"default",fn:function(dialog){return [_c(VCard,[_c('FocusLoop',{attrs:{"is-visible":_vm.activeTrap}},[_c(VCardTitle,{staticClass:"text-h5 accessibility-header lighten-2"},[_vm._v(" Accesibility Mode ")]),_c(VTabs,{attrs:{"grow":""}},[_c(VTab,{staticClass:"accessibility-tab"},[_vm._v(" Sidebar Content ")]),_c(VTabItem,[_vm._l((_vm.pageData.children),function(item,i){return _c(VListItemGroup,{key:_vm.pageData.id + 'page_' + i,attrs:{"link":""},on:{"click":function($event){_vm.markerClicked(item), (dialog.value = false)}}},[(item.type == 'mappage')?_c(VListItem,{staticClass:"accessibility-btn",on:{"click":function($event){_vm.markerClicked(item), (dialog.value = false)}}},[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e()],1)}),_c(VListItem,{staticClass:"accessibility-btn",on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }$event.preventDefault();return _vm.markerClicked()},"click":function($event){(_vm.sidebarLightbox = true), (dialog.value = false)}}},[_vm._v("Sidebar Overview ")]),(_vm.pageData.next_map !== null)?_c(VListItem,{staticClass:"accessibility-btn",on:{"click":function($event){return _vm.gotoPage(_vm.pageData.next_map.html_url)}}},[_vm._v(" Next - "+_vm._s(_vm.pageData.next_map.title)+" ")]):_vm._e(),(
                  _vm.pageData.root_map !== null &&
                  _vm.pageData.root_map.id !== _vm.pageData.id
                )?_c(VListItem,{staticClass:"accessibility-btn",on:{"click":function($event){return _vm.gotoPage(_vm.pageData.root_map.html_url)}}},[_vm._v(" Home - "+_vm._s(_vm.pageData.root_map.title)+" ")]):_vm._e()],2),_c(VTab,{staticClass:"accessibility-tab"},[_vm._v(" Map Content ")]),_c(VTabItem,[_vm._l((_vm.pageData.children),function(item,i){return _c(VListItemGroup,{key:_vm.pageData.id + 'page_' + i,staticClass:"accessibility-btn",attrs:{"link":""},on:{"click":function($event){_vm.markerClicked(item), (dialog.value = false)}}},[(
                    item.type == 'mappopup' ||
                    item.type == 'mapzoomtolocation'
                  )?_c(VListItem,{on:{"click":function($event){_vm.markerClicked(item), (dialog.value = false)}}},[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e()],1)}),_c(VListItem,{staticClass:"accessibility-btn",on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }$event.preventDefault();return _vm.markerClicked()},"click":function($event){(_vm.mapLightbox = true), (dialog.value = false)}}},[_vm._v("Map Overview")]),(_vm.pageData.next_map !== null)?_c(VListItem,{staticClass:"accessibility-btn",on:{"click":function($event){return _vm.gotoPage(_vm.pageData.next_map.html_url)}}},[_vm._v(" Next - "+_vm._s(_vm.pageData.next_map.title)+" ")]):_vm._e(),(
                  _vm.pageData.root_map !== null &&
                  _vm.pageData.root_map.id !== _vm.pageData.id
                )?_c(VListItem,{staticClass:"accessibility-btn",on:{"click":function($event){return _vm.gotoPage(_vm.pageData.root_map.html_url)}}},[_vm._v(" Home - "+_vm._s(_vm.pageData.root_map.title)+" ")]):_vm._e()],2)],1),_c(VDivider),_c(VBtn,{staticClass:"close-accessibility-btn",attrs:{"tabindex":"0"},on:{"click":function($event){_vm.closeAccessibilityLightbox(), (dialog.value = false)}}},[_vm._v("Close")])],1)],1)]}}]),model:{value:(_vm.accessibilityModalFlag),callback:function ($$v) {_vm.accessibilityModalFlag=$$v},expression:"accessibilityModalFlag"}}),_c('MapLightbox',{attrs:{"pageData":_vm.pageData,"showLightbox":_vm.showLightbox,"accessibilityMode":_vm.accessibilityMode,"mapLightbox":_vm.mapLightbox},on:{"closed":function($event){(_vm.mapLightbox = false), (_vm.accessibilityModalFlag = true)}}}),(_vm.sidebarLightbox)?_c('LightboxBase',{ref:"lightbox",attrs:{"showLightbox":_vm.showLightbox},on:{"closed":function($event){(_vm.sidebarLightbox = false), (_vm.accessibilityModalFlag = true)}}},[_c('Sidebar',{class:'map-sidebar-accessibility-mode',attrs:{"pageData":_vm.pageData}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }