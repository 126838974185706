import { render, staticRenderFns } from "./WebPage.vue?vue&type=template&id=63f5e182&scoped=true&"
import script from "./WebPage.vue?vue&type=script&lang=js&"
export * from "./WebPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63f5e182",
  null
  
)

export default component.exports