<template>
<v-container class="justify-center"  v-html="data">

</v-container>
</template>

<script>
// import StreamfieldBlock from "./streamfieldBlock.vue";

export default {
  name: "embeddedHtmlBlock",
  props: ["data"],
  data: () => ({
    htmlRawCode: this.data
  }),
  mounted() {
  },
  components: {  },
  methods: {
  },
};
</script>

<style scoped>
</style>